import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Breakpoints, InquiryRequestContents } from '@/constants/constants';
import { useDispatch } from 'react-redux';
import { Inquiry, InquiryType } from 'openapi/kusabi-frontgw-spec';
import * as inquiry from '@/state/modules/inquiry';
import { Routings } from '@/common/routings';
import { FormState, schema } from '../../state';
import WrappedSubmitButton from '@/components/atoms/SubmitButton/WrappedSubmitButton';
import TelArea from '../../CommonComponent/TelArea';
import MailArea from '../../CommonComponent/MailArea';
import {
  Td,
  Th,
  Tr,
  Table,
  TBody,
  NameAreaContainer,
  NameArea,
  InnerTd,
  NameTextField,
  NameTextFieldContainer,
  NameTextFieldWrapper,
  TextArea,
} from '@/components/atoms/CommonStyles';
import FormLabel from '@/components/atoms/FormLabel';
import Select from '@/components/atoms/Select';
import TextField from '@/components/atoms/TextField';
import styled from 'styled-components';
import RadioButton from '@/components/atoms/RadioButton';
import { getPartneridForSessionStorage } from '@/common/utilities';
import Terms from '@/components/atoms/Terms';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const TypeArea = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const InquiryTypeMap = {
  SELL: {
    display: '物件の売却',
    value: InquiryType.SELL,
  },
  BUY: {
    display: '物件の購入',
    value: InquiryType.BUY,
  },
  BUY_REPLACEMENT: {
    display: '物件の買替え',
    value: InquiryType.BUY_REPLACEMENT,
  },
} as const;

const UnAuthenticatedForm = () => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      type: InquiryType.SELL,
      request: InquiryRequestContents.buyer.unAuthenticated[InquiryType.SELL][0],
    },
  });
  const watchType = watch('type');

  register('type');

  /**
   * ラジオボタン変更時はご用件のセレクトボックスを初期化する
   */
  useEffect(() => {
    setValue('request', InquiryRequestContents.buyer.unAuthenticated[watchType][0]);
  }, [watchType]);

  const onSubmit: SubmitHandler<FormState> = (data) => {
    const param: Inquiry = {
      firstName: data.firstName,
      lastName: data.lastName,
      firstNameKana: data.firstNameKana,
      lastNameKana: data.lastNameKana,
      mailAddress: data.mailAddress,
      type: data.type as InquiryType,
      request: data.request,
      content: data.content,
      partnerId: getPartneridForSessionStorage(),
    };

    dispatch(inquiry.operations.postInquiries(param));
    navigate(Routings.buyerCompleted.location);
  };

  return (
    <>
      <Table>
        <TBody>
          <Tr>
            <Th>
              <FormLabel value="お名前" isRequired />
            </Th>
            <Td>
              <InnerTd>
                <NameAreaContainer>
                  <NameArea>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="lastName">姓</label>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => <NameTextField id="lastName" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
                    </NameTextFieldContainer>

                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="firstName">名</label>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field }) => <NameTextField id="firstName" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.firstName && <ErrorMessage>{errors.firstName.message}</ErrorMessage>}
                    </NameTextFieldContainer>
                  </NameArea>

                  <NameArea>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="lastNameKana">せい</label>
                        <Controller
                          name="lastNameKana"
                          control={control}
                          render={({ field }) => <NameTextField id="lastNameKana" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.lastNameKana && <ErrorMessage>{errors.lastNameKana.message}</ErrorMessage>}
                    </NameTextFieldContainer>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="firstNameKana">めい</label>
                        <Controller
                          name="firstNameKana"
                          control={control}
                          render={({ field }) => <NameTextField id="firstNameKana" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.firstNameKana && <ErrorMessage>{errors.firstNameKana.message}</ErrorMessage>}
                    </NameTextFieldContainer>
                  </NameArea>
                </NameAreaContainer>
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="メールアドレス" isRequired htmlFor="mailAddress" />
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="mailAddress"
                  control={control}
                  render={({ field }) => <TextField type="text" id="mailAddress" {...field} />}
                />
                {errors?.mailAddress && <ErrorMessage>{errors.mailAddress.message}</ErrorMessage>}
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="電話番号" htmlFor="phoneNumber" />
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => <TextField type="text" id="phoneNumber" {...field} />}
                />
                {errors?.phoneNumber && <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>}
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="ご検討内容" isRequired />
            </Th>
            <Td>
              <InnerTd>
                <TypeArea>
                  {Object.entries(InquiryTypeMap).map(([key, obj]) => (
                    <div key={key}>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <RadioButton
                            {...field}
                            defaultChecked={key === 'SELL'}
                            id={key}
                            label={obj.display}
                            value={obj.value}
                          />
                        )}
                      />
                    </div>
                  ))}
                </TypeArea>
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="ご用件" htmlFor="request" />
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="request"
                  control={control}
                  render={({ field }) => (
                    <Select id="request" {...field}>
                      {InquiryRequestContents.buyer.unAuthenticated[watchType]?.map((request, i) => (
                        <option key={i} value={request}>
                          {request}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="お問合せ内容" htmlFor="content" />
            </Th>
            <Td>
              <div>
                <Controller
                  name="content"
                  control={control}
                  render={({ field }) => <TextArea id="content" {...field} />}
                />
              </div>
              {errors?.content && <ErrorMessage>{errors.content.message}</ErrorMessage>}
            </Td>
          </Tr>
        </TBody>
      </Table>

      <Terms />
      <WrappedSubmitButton withTerms label="フォームの内容を送信する" onClick={handleSubmit(onSubmit)} />
      <TelArea />
      <MailArea />
    </>
  );
};

export default UnAuthenticatedForm;
