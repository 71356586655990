import React from 'react';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import BuyerInquiry from '@/components/organisms/Inquiry/BuyerInquiry';
import { Wrapper } from '@/components/layout/common';

type Props = {
  location: {
    state: {
      propertyId: number;
    };
  };
};

const BuyerInquiryPage = (props: Props) => {
  const propertyId = props.location.state?.propertyId;

  return (
    <Wrapper>
      <BuyerHeader displayPattern="logo" />
      <BuyerInquiry propertyId={propertyId} />
      <BuyerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(BuyerInquiryPage);
