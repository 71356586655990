import isAuthenticated from '@/utils/authentication';
import React, { useEffect, useState } from 'react';
import UnAuthenticatedForm from './UnAuthenticatedForm';
import AuthenticatedForm from './AuthenticatedForm';
import { Description, Content, ContentWrapper } from '@/components/atoms/CommonStyles';
import PageTitle from '@/components/atoms/PageTitle';
import { Main } from '@/components/layout/common';

type Props = {
  propertyId?: number;
};

const BuyerInquiry = ({ propertyId }: Props) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const getAuth = async () => {
      const result = await isAuthenticated();
      setAuthenticated(result);
    };
    getAuth();
  }, []);

  return (
    <Main>
      <PageTitle>お問合せ</PageTitle>
      <ContentWrapper>
        <Content>
          <Description>お問合せ・ご相談内容をご入力ください</Description>
          {authenticated ? <AuthenticatedForm propertyId={propertyId} /> : <UnAuthenticatedForm />}
        </Content>
      </ContentWrapper>
    </Main>
  );
};

export default BuyerInquiry;
