import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import { REGEXP_PHONE } from '@/constants/constants';

export const schema = yup.object({
  phoneNumber: yup
    .string()
    .trim()
    .max(32, YupErrorMessage.string.max)
    .test('phoneNumber_matches', YupErrorMessage.string.phoneNumber, (value) => {
      if (value) {
        return REGEXP_PHONE.test(value);
      }
      return true;
    })
    .label('電話番号'),
  type: yup.string().trim().required(YupErrorMessage.required).label('ご検討内容'),
  request: yup.string().trim().required(YupErrorMessage.required).label('ご用件'),
  content: yup.string().trim().max(65535, YupErrorMessage.string.max).label('お問合せ内容'),
});

export type FormState = yup.InferType<typeof schema>;
