import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Inquiry, InquiryType, PropertyStatus } from 'openapi/kusabi-frontgw-spec';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoints, InquiryRequestContents } from '@/constants/constants';
import * as inquiry from '@/state/modules/inquiry';
import * as member from '@/state/modules/member';
import * as buyer from '@/state/modules/buyer';
import { Routings } from '@/common/routings';
import { FormState, schema } from './state';
import TelArea from '../../CommonComponent/TelArea';
import WrappedSubmitButton from '@/components/atoms/SubmitButton/WrappedSubmitButton';
import { Td, Th, Tr, Table, TBody, TextArea, InnerTd } from '@/components/atoms/CommonStyles';
import FormLabel from '@/components/atoms/FormLabel';
import TextField from '@/components/atoms/TextField';
import Select from '@/components/atoms/Select';
import styled from 'styled-components';
import { getPartneridForSessionStorage } from '@/common/utilities';
import theme from '@/styles/theme';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const PropertyField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 100%;
    align-items: start;
  }
`;

const PropertyLabel = styled.label`
  font: ${theme.fonts.bold_12_24};
  color: #333333;
`;

const PropertyText = styled.label`
  margin-left: 15px;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
`;

type Props = {
  propertyId?: number;
};

const AuthenticatedForm = ({ propertyId }: Props) => {
  const dispatch = useDispatch();
  const memberInfo = useSelector(member.selectors.getMember);
  const propertyInfo = useSelector(buyer.selectors.getSalePropertyDetail);

  const [isSale, setIsState] = useState(false);

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      type: InquiryType.BUY,
      request: InquiryRequestContents.buyer.authenticated[InquiryType.BUY][0],
    },
  });
  const watchType = watch('type');

  /**
   * 初期表示時に会員情報を取得する
   */
  useEffect(() => {
    dispatch(member.operations.getMember());
  }, []);

  /**
   * 物件詳細画面から遷移してきた場合は物件情報を取得する
   */
  useEffect(() => {
    if (!propertyId) return;
    dispatch(buyer.operations.getSalePropertyDetail(propertyId));
  }, []);

  /**
   * 物件情報取得後、問合せ種別を更新する
   */
  useEffect(() => {
    if (propertyId && propertyInfo.propertyStatus === PropertyStatus.SALE) {
      setIsState(true);
      setValue('type', InquiryType.BUY_PROPERTY);
      setValue('request', InquiryRequestContents.buyer.authenticated[InquiryType.BUY_PROPERTY][0]);
    }
  }, [propertyInfo]);

  const onSubmit: SubmitHandler<FormState> = (data) => {
    const param: Inquiry = {
      propertyId: isSale ? propertyInfo.id : undefined,
      memberId: memberInfo.id,
      firstName: memberInfo.firstName,
      lastName: memberInfo.lastName,
      firstNameKana: memberInfo.firstNameKana,
      lastNameKana: memberInfo.lastNameKana,
      mailAddress: memberInfo.mailAddress,
      phoneNumber: data.phoneNumber ? data.phoneNumber : memberInfo.phoneNumber,
      type: data.type as InquiryType,
      request: data.request,
      content: data.content,
      partnerId: getPartneridForSessionStorage(),
    };

    dispatch(inquiry.operations.postInquiries(param));
    navigate(Routings.buyerCompleted.location);
  };

  return (
    <>
      <Table>
        <TBody>
          {isSale && (
            <Tr>
              <Th>
                <FormLabel value="お問合せ物件" />
              </Th>
              <Td>
                <InnerTd>
                  <PropertyField>
                    <PropertyLabel>物件ID</PropertyLabel>
                    <PropertyText>{propertyInfo.displayId}</PropertyText>
                  </PropertyField>
                  <PropertyField>
                    <PropertyLabel>物件名</PropertyLabel>
                    <PropertyText>{propertyInfo.mansionName}</PropertyText>
                  </PropertyField>
                </InnerTd>
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>
              <FormLabel value="電話番号" htmlFor="phoneNumber" />
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => <TextField type="text" id="phoneNumber" {...field} />}
                />
                {errors?.phoneNumber && <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>}
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="ご用件" htmlFor="request" />
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="request"
                  control={control}
                  render={({ field }) => (
                    <Select id="request" {...field}>
                      {InquiryRequestContents.buyer.authenticated[watchType].map((request, i) => (
                        <option key={i} value={request}>
                          {request}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </InnerTd>
            </Td>
          </Tr>
          <Tr>
            <Th>
              <FormLabel value="お問合せ内容" htmlFor="content" />
            </Th>
            <Td>
              <div>
                <Controller
                  name="content"
                  control={control}
                  render={({ field }) => <TextArea id="content" {...field} />}
                />
              </div>
              {errors?.content && <ErrorMessage>{errors.content.message}</ErrorMessage>}
            </Td>
          </Tr>
        </TBody>
      </Table>

      <WrappedSubmitButton label="フォームの内容を送信する" onClick={handleSubmit(onSubmit)} />
      <TelArea />
    </>
  );
};

export default AuthenticatedForm;
