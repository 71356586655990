import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > input {
    position: absolute;
    opacity: 0;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 13px 20px;
    margin-bottom: 20px;
  }
`;

const Label = styled.label`
  margin-right: 40px;
  font: ${theme.fonts.normal_16_32};

  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    border: 1px solid #cccccc;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  input:checked + &:after {
    content: '';
    position: absolute;
    left: 5px;
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #d1a827;
  }
`;

// HTML標準input要素のpropsを拡張することで、react-hook-formをそのまま適用可能にしている
type Props = {
  name: string;
  id: string;
  label: string;
} & React.ComponentProps<'input'>;

const RadioButton = (props: Props) => {
  return (
    <Wrapper>
      <input {...props} type="radio" id={`${props.name}-${props.id}`} />
      <Label htmlFor={`${props.name}-${props.id}`}>{props.label}</Label>
    </Wrapper>
  );
};

export default RadioButton;
